<template>
  <span v-html="LOG_CODE[log.context].getDescription(log)" />
</template>

<script>
import { LOG_CODE } from "@/helpers/variables/logs";

export default {
  name: "Description",
  props: {
    log: { type: Object, required: true },
  },

  data: () => ({
    LOG_CODE,
  }),
};
</script>
