<template>
  <v-chip small>{{ logContext }}</v-chip>
</template>

<script>
import { LOG_CONTEXT } from "@/helpers/variables/logs";
export default {
  name: "Event",
  props: {
    context: { type: String, required: true },
  },
  data: () => ({ LOG_CONTEXT }),
  computed: {
    logContext() {
      return LOG_CONTEXT[this.context] || "Não encontrado";
    },
  },
};
</script>
