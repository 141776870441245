<template>
  <div>
    <v-icon left dense v-text="iconTextCode" :color="iconColor" />

    {{
      getActionTranslation(
        LOG_CODE[log.context].action ||
          log.object.status_code ||
          log.object.action ||
          "NOT_FOUND"
      )
    }}
  </div>
</template>
<script>
import { getActionTranslation } from "@/helpers/services/logs";
import {
  LOG_CODE,
  LOG_ICON_BY_ACTION,
  LOG_ICON_BY_STATUS_CODE,
  LOG_ICON_COLOR_BY_ACTION,
  LOG_ICON_COLOR_BY_STATUS_CODE,
} from "@/helpers/variables/logs";
export default {
  name: "Action",
  props: {
    log: { type: Object, default: () => {}, required: true },
  },
  data: () => ({
    LOG_CODE,
    LOG_ICON_COLOR_BY_ACTION,
    LOG_ICON_BY_ACTION,
    LOG_ICON_COLOR_BY_STATUS_CODE,
    LOG_ICON_BY_STATUS_CODE,
  }),
  methods: { getActionTranslation },
  computed: {
    iconTextCode() {
      return (
        LOG_CODE[this.log.context].icon ||
        LOG_ICON_BY_STATUS_CODE[this.log.object.status_code] ||
        LOG_ICON_BY_ACTION[this.log.object.action] ||
        LOG_ICON_BY_STATUS_CODE["UNKNOWN_ERROR"]
      );
    },
    iconColor() {
      return (
        LOG_CODE[this.log.context].color ||
        LOG_ICON_COLOR_BY_STATUS_CODE[this.log.object.status_code] ||
        LOG_ICON_COLOR_BY_ACTION[this.log.object.action] ||
        LOG_ICON_BY_STATUS_CODE["UNKNOWN_ERROR"]
      );
    },
  },
};
</script>
